var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('image-selector',{attrs:{"defaultImage":_vm.reseller.logo,"ressource_name":"resellers","ressource_id":_vm.reseller.id ? _vm.reseller.id : 0,"field":"logo"},on:{"imageChanged":function (file_url) {
        _vm.reseller.logo = file_url;
        _vm.onFormChanged();
      }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.attachment}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.NAME')) + " (*)"),"placeholder":_vm.$t('COMMON.NAME')},on:{"change":function () {
        _vm.onFormChanged();
      }},model:{value:(_vm.reseller.name),callback:function ($$v) {_vm.$set(_vm.reseller, "name", $$v)},expression:"reseller.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}}),_c('base-input',{attrs:{"label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.reseller.excerpt),callback:function ($$v) {_vm.$set(_vm.reseller, "excerpt", $$v)},expression:"reseller.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.EMAIL')) + " (*)"),"placeholder":_vm.$t('COMMON.EMAIL')},on:{"change":function () {
        _vm.onFormChanged();
      }},model:{value:(_vm.reseller.email),callback:function ($$v) {_vm.$set(_vm.reseller, "email", $$v)},expression:"reseller.email"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.email}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.PHONE')) + " (*)")}},[_c('phone-number-input',{attrs:{"phoneNumber":_vm.reseller.phone},on:{"phoneNumberChanged":function (phone) {
          _vm.reseller.phone = phone;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.ADDRESS')) + " (*)"),"placeholder":_vm.$t('COMMON.ADDRESS')},on:{"change":function () {
        _vm.onFormChanged();
      }},model:{value:(_vm.reseller.address),callback:function ($$v) {_vm.$set(_vm.reseller, "address", $$v)},expression:"reseller.address"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.address}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.OWNER')) + " (*)"),"placeholder":_vm.$t('COMMON.OWNER')}},[_c('user-selector',{attrs:{"user":_vm.reseller.owner.id,"filterable":true,"showAll":false,"filterResellerNull":_vm.reseller.id ? false : true,"filterReseller":_vm.reseller.id ? _vm.reseller.id : null,"filterOrganizationNull":true},on:{"userChanged":function (userId) {
          _vm.reseller.owner.id = userId;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.owner}}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.reseller.id ? _vm.$t("RESELLERS.EDIT_RESELLER") : _vm.$t("RESELLERS.ADD_RESELLER"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }